(function($) {
  "use strict"; // Start of use strict

  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top - 56)
        }, 1000, "easeInOutExpo");
        return false;
      }
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function() {
    $('.navbar-collapse').collapse('hide');
  });

  var $dynamicNavLink = $('.dynamic-nav-link');

  $dynamicNavLink.each(function () {
    $(this).attr('href', $(this).attr('data-href'))
  });

  $dynamicNavLink.click(function() {
    $('.navbar-collapse').collapse('hide');

    var target = $('.id-content');
    if (target.length) {
      $('html, body').animate({
        scrollTop: (target.offset().top - 56)
      }, 1000, "easeInOutExpo");
    }
    return true;
  });

  // Activate scrollspy to add active class to navbar items on scroll
  // $('body').scrollspy({
  //   target: '#mainNav',
  //   offset: 57
  // });

  // Collapse Navbar
  var navbarCollapse = function() {
    const $mainNav = $("#mainNav");
    if ($mainNav.length) {
      if ($mainNav.offset().top > 100) {
        $mainNav.addClass("navbar-shrink");
      } else {
        $mainNav.removeClass("navbar-shrink");
      }
    }
  };
  // Collapse now if page is not at top
  navbarCollapse();
  // Collapse the navbar when page is scrolled
  $(window).scroll(navbarCollapse);

})(jQuery); // End of use strict
